import * as constant from './constant'

// PUBLIC WEBSITE PAGES
export const WEBSITE_VIEW_RATES = constant.PUBLIC_WEBSITE + '/rates-terms/'

// FRONT END ROUTES

// home
export const APPLICATION_HOME_PAGE = "/";
export const APPLICATION_LOGIN_PAGE = "/login";

/**
 * AUTH
 * ------------------------------------------------------------------------- */
export const LOGIN = "/login";
export const STATE_LOGIN = "/statelogin";
export const START_APP = "/startapp";
export const RESET_PASSWORD = "/resetpassword";
export const UPDATE_PASSWORD_WITH_TOKEN = "/updatepassword/:userId/uniqueResetId/:token";
export const VERIFY_ACCOUNT_ROUTE_PARAMETER = "verifyaccount";
export const VERIFY_ACCOUNT = "/verifyaccount/:userId";
export const VERIFY_ACCOUNT_WITH_TOKEN = "/verifyemail/:userId/token/:token";
export const OTP_EMAIL_LOGIN_PAGE = "/login/otp/email";
export const EMAIL_LOGIN_PAGE = "/email/login";

/**
 * APPLICATION
 * ------------------------------------------------------------------------- */

// organic flow
export const APPLICATION_BASE_ROUTE_PARAMETER = "apply";
export const APPLICATION_STATE_INFO = "stateinfo";
export const APPLICATION_START_APP = "startapp";
export const APPLICATION_STATE_SELECTION = "stateselection";
export const APPLICATION_STATE_LOGIN = "statelogin";
export const APPLICATION_REQUIREMENTS = "apprequirements";
export const APPLICATION_CREATE_PASSWORD = "createpassword";
export const APPLICATION_ACCOUNT_TERMS = "accountterms";
export const APPLICATION_PERSONAL_INFO = "personalinfo";
export const APPLICATION_VERIFY_PHONE = "verify-your-phone";
export const APPLICATION_ADDRESS_INFO = "addressinfo";
export const APPLICATION_LIVING_SITUATION = "livingsituation";
export const APPLICATION_EMPLOYMENT_INFO = "employmentinfo";
export const APPLICATION_PAYMENT_SCHEDULE = "paymentschedule";
export const APPLICATION_BANKING_INFO = "bankinginfo";
export const APPLICATION_PRE_APPROVED = "preapproved";
export const APPLICATION_LOAN_DOCS = "loandocs";
export const APPLICATION_REJECT = "reject";
export const APPLICATION_ACCESS_DENIED = "access-denied";
export const APPLICATION_FINAL_STEPS = "finalstep";
export const APPLICATION_VERIFYYOURIDENTITY = "verifyyouridentity";
export const REAPPLY_BASE_ROUTE_PARAMETER = "reapply";
export const APPLICATION_INCOME = "income";
export const APPLICATION_EMAIL_VERIFICATION = "emailverification";
export const APPLICATION_EMAIL_VERIFY = "email/verify";

//
export const APPLICATION_APPLY_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}`;
export const APPLICATION_STATE_INFO_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_STATE_INFO}`;
export const APPLICATION_START_APP_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_START_APP}`;
export const APPLICATION_STATE_SELECTION_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_STATE_SELECTION}`;
export const APPLICATION_APPLY_REQUIREMENTS_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_REQUIREMENTS}`;
export const APPLICATION_CREATE_PASSWORD_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_CREATE_PASSWORD}`;
export const APPLICATION_OTP_EMAIL_VERIFICATION = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_EMAIL_VERIFY}`;
export const APPLICATION_ACCOUNT_TERMS_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_ACCOUNT_TERMS}`;
export const APPLICATION_PERSONAL_INFO_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_PERSONAL_INFO}`;
export const APPLICATION_VERIFY_PHONE_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_VERIFY_PHONE}`;
export const APPLICATION_ADDRESS_INFO_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_ADDRESS_INFO}`;
export const APPLICATION_LIVING_SITUATION_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_LIVING_SITUATION}`;
export const APPLICATION_PAYMENT_SCHEDULE_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_PAYMENT_SCHEDULE}`;
export const APPLICATION_PRE_APPROVED_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_PRE_APPROVED}`;
export const APPLICATION_EMPLOYMENT_INFO_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_EMPLOYMENT_INFO}`;
export const APPLICATION_BANKING_INFO_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_BANKING_INFO}`;
export const APPLICATION_KNOWYOURCUSTOMER_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_VERIFYYOURIDENTITY}`;
export const APPLICATION_LOAN_DOCS_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_LOAN_DOCS}`;
export const APPLICATION_FINAL_STEPS_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_FINAL_STEPS}`;
export const APPLICATION_REJECT_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_REJECT}`;
export const APPLICATION_ACCESS_DENIED_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${APPLICATION_ACCESS_DENIED}`;
export const REAPPLY_APPLICATION_PAGE = `/${REAPPLY_BASE_ROUTE_PARAMETER}`;
export const REAPPLY_APPLICATION_REQUIREMENTS_PAGE = `/${REAPPLY_BASE_ROUTE_PARAMETER}/${APPLICATION_REQUIREMENTS}`;
export const REAPPLY_PERSONAL_INFO_PAGE = `/${REAPPLY_BASE_ROUTE_PARAMETER}/${APPLICATION_PERSONAL_INFO}`;
export const REAPPLY_EMPLOYMENT_INFO_PAGE = `/${REAPPLY_BASE_ROUTE_PARAMETER}/${APPLICATION_EMPLOYMENT_INFO}`;

// external flow
export const EXTERNAL_APPLICATION_WELCOME = "welcome";
export const EXTERNAL_APPLICATION_PHONE_VERIFICATION = "contactinfo";
export const EXTERNAL_APPLICATION_CREATE_ACCOUNT = "createaccount";
//
export const APPLICATION_EXTERNAL_WELCOME_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${EXTERNAL_APPLICATION_WELCOME}`;
export const APPLICATION_EXTERNAL_CREATE_ACCOUNT_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${EXTERNAL_APPLICATION_CREATE_ACCOUNT}`;
export const APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE = `/${APPLICATION_BASE_ROUTE_PARAMETER}/${EXTERNAL_APPLICATION_PHONE_VERIFICATION}`;

// opt out
export const OPTOUT = "/optout";

/**
 * DASHBOARD
 -------------------------------------------------------------------------- */

export const DASHBOARD_BASE_ROUTE_PARAMETER = "dashboard";
export const PAGE_DASHBOARD = "/dashboard";
export const DASHBOARD_LOG_OUT = "logout";
export const DASHBOARD_RESEND_EMAIL = "resendemail";
export const DASHBOARD_BANK_VERIFICATION = "bankverification";
export const DASHBOARD_BANK_VERIFICATION_INFO = `bank-verification-info`;
export const DASHBOARD_BANK_VERIFICATION_REDIRECT = `bank-verification-redirect`;
export const DASHBOARD_CONFIRM_BANK_VERIFICATION = `confirm-bank-verification`;
export const DASHBOARD_DRAW_REQUEST = "drawrequest";
export const DASHBOARD_CANCEL_DRAW_REQUEST = "canceldraw";
export const DASHBOARD_CANCEL_ADVANCE_REQUEST = "canceladvance";
export const DASHBOARD_DRAW_RESULT = "drawresult";
export const DASHBOARD_RELOAN = "reloan";
export const DASHBOARD_CREDIT_LIMIT_INCREASE = "creditlimitincrease";
export const DASHBOARD_CREDIT_LIMIT_INCREASE_CONFIRMATION = "increaseconfirmation";
export const DASHBOARD_INVALID_CL_OFFER = "invalidoffer";
export const DASHBOARD_AGREEMENTS = "agreements";
export const DASHBOARD_IDENTITY_VERIFICATION = "identity-verification";
export const OFFERS_BASE_ROUTE_PARAMETER = "offers";
export const DEBT_VALIDATION_RESPONSE_PAGE = "debtvalidationresponse";
export const OFFERS_PAGE = "/offers";
export const CHAT_PAGE = "/webchat";
export const FEEDBACK_FORM_PAGE = "/feedback";
export const FEEDBACK_FORM_PAGE_SUBMITTED = "/feedbacksubmitted";
export const DASHBOARD_AGREEMENT = `${DASHBOARD_AGREEMENTS}/:documentId`;
export const DASHBOARD_HISTORY = "documents";
export const DASHBOARD_HISTORY_ITEM = `${DASHBOARD_HISTORY}/:loanId`;
export const DASHBOARD_STATEMENTS = "statements";
export const DASHBOARD_STATEMENT = `${DASHBOARD_STATEMENTS}/:id`;
export const DASHBOARD_SIGNAGREEMENT = "signagreement";
export const ADD_PAYMENT = "addpayment";
export const CONFIRM_PAYMENT = "confirmpayment";
export const PAYMENT_CONFIRMATION = "confirmation";
export const PAYMENT_ERROR = "paymenterror"
export const DASHBOARD_DOCUMENT_UPLOAD = "documentupload";
export const UPDATE_PASSWORD = "updatepassword";

//
export const DASHBOARD_LOG_OUT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_LOG_OUT}`;
export const DASHBOARD_RESEND_EMAIL_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_RESEND_EMAIL}`;
export const DASHBOARD_OTP_EMAIL_VERIFICATION = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/email/verify`;

export const DASHBOARD_BANK_VERIFICATION_INFO_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_BANK_VERIFICATION_INFO}`;
export const DASHBOARD_CONFIRM_BANK_VERIFICATION_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_CONFIRM_BANK_VERIFICATION}`;
export const DASHBOARD_BANK_VERIFICATION_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_BANK_VERIFICATION}`;
export const DASHBOARD_BANK_VERIFICATION_REDIRECT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_BANK_VERIFICATION_REDIRECT}`;
export const DASHBOARD_DRAW_REQUEST_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_DRAW_REQUEST}`;
export const DASHBOARD_CANCEL_DRAW_REQUEST_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_CANCEL_DRAW_REQUEST}`;
export const DASHBOARD_CANCEL_ADVANCE_REQUEST_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_CANCEL_ADVANCE_REQUEST}`;
export const DASHBOARD_DRAW_RESULT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_DRAW_RESULT}`;
export const DASHBOARD_AGREEMENTS_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_AGREEMENTS}`;
export const DASHBOARD_AGREEMENT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_AGREEMENT}`;
export const DASHBOARD_HISTORY_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_HISTORY}`;
export const DASHBOARD_HISTORY_ITEM_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_HISTORY_ITEM}`;
export const DASHBOARD_HISTORY_ITEM__AGREEMENT__PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_HISTORY_ITEM}/${DASHBOARD_AGREEMENT}`;
export const DASHBOARD_HISTORY_ITEM__STATEMENTS__PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_HISTORY_ITEM}/${DASHBOARD_STATEMENT}`;
export const DASHBOARD_RELOAN_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_RELOAN}`;
export const DASHBOARD_CREDIT_INCREASE_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_CREDIT_LIMIT_INCREASE}`;
export const DASHBOARD_CREDIT_INCREASE_CONFIRMATION_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_CREDIT_LIMIT_INCREASE_CONFIRMATION}`;
export const DASHBOARD_INVALID_CL_OFFER_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_INVALID_CL_OFFER}`;
export const DASHBOARD_LOAN_DOCS_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_SIGNAGREEMENT}`;
export const ONLINE_PAYMENT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${ADD_PAYMENT}`;
export const CONFIRM_PAYMENT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${CONFIRM_PAYMENT}`;
export const ONLINE_PAYMENT_CONFIRMATION_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${PAYMENT_CONFIRMATION}`;
export const ONLINE_PAYMENT_ERROR_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${PAYMENT_ERROR}`;
export const DASHBOARD_IDENTITY_VERIFICATION_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_IDENTITY_VERIFICATION}`;

//
export const DASHBOARD_VIEW_MYACCOUNT = "myaccount";
export const DASHBOARD_VIEW_AGREEMENT = "viewagreement";
export const DASHBOARD_VIEW_HISTORY = "viewhistory";
export const DASHBOARD_VIEW_PROFILE = "viewprofile";
export const DASHBOARD_VIEW_DOCUMENT_UPLOAD = "documentupload";
export const DASHBOARD_DOCUMENT_UPLOAD_CONFIRMATION = "uploadconfirmation";
export const DASHBOARD_VIEW_SCHEDULE = "viewschedule";
export const DASHBOARD_VIEW_STATEMENTS = "viewstatements";
//
export const DASHBOARD_VIEW_ACCOUNT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_VIEW_MYACCOUNT}`;
export const DASHBOARD_VIEW_HISTORY_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_VIEW_HISTORY}`;
export const DASHBOARD_VIEW_PROFILE_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_VIEW_PROFILE}`;
export const DASHBOARD_UPDATE_PASSWORD = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${UPDATE_PASSWORD}`;

export const DASHBOARD_VIEW_DOCUMENT_UPLOAD_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_VIEW_DOCUMENT_UPLOAD}`;
export const DASHBOARD_VIEW_DOCUMENT_UPLOAD_CONFIRMATION = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_DOCUMENT_UPLOAD_CONFIRMATION}`;
export const DASHBOARD_VIEW_SCHEDULE_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_VIEW_SCHEDULE}`;
export const DASHBOARD_VIEW_STATEMENTS_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_STATEMENTS}`;

// Dashboard TDC
export const DASHBOARD_VIEW_TDC = "tdc";
export const DASHBOARD_TDC_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_VIEW_TDC}`;
export const DASHBOARD_TDC_LOANSUMMARY = "loanSummary";
export const DASHBOARD_TDC_MONEYKEY_REDIRECT = "moneykeyredirect";
export const DASHBOARD_TDC_THIRDPARTY_REDIRECT = "thirdpartyredirect";
export const DASHBOARD_TDC_UNSUPPORTED_REDIRECT = "unsupported";


/**
 * API ENDPOINTS
 -------------------------------------------------------------------------- */

export const API_BASE_PATH = "/api";
export const WEB_API_BASE_PATH = API_BASE_PATH + "/web";
export const JAG_API_BASE_PATH = API_BASE_PATH + "/jag";
export const HB_API_BASE_PATH = API_BASE_PATH + "/hotbox";
export const LOAN_API_BASE_PATH = API_BASE_PATH + "/loan";
export const LMS_WEB_SERVICE_BASE_PATH = "/lmswebservice";
export const ONE_TIME_PASSWORD = "/one-time-password";

// User controller
export const WEB_API_USER_BASE_PATH = "/user";
export const WEB_API_USER_LOGIN = WEB_API_USER_BASE_PATH + "/sec_allowed";
export const WEB_API_USER_LOGOUT = WEB_API_USER_BASE_PATH + "/logout";
export const WEB_API_GET_TOKENS = WEB_API_USER_BASE_PATH + "/token/generate";
export const WEB_API_USER_UNIQUEEMAIL = WEB_API_USER_BASE_PATH + "/isUniqueEmail/:email/organization/:organizationId";
export const WEB_API_USER_RESET_PASSWORD = WEB_API_USER_BASE_PATH + "/resetPassword";
export const WEB_API_USER_RESET_PASSWORD_BY_USERID = WEB_API_USER_BASE_PATH + "/:id/resetPassword";
export const WEB_API_USER_EMAIL_RESET_PASSWORD_LINK =
    WEB_API_USER_BASE_PATH + "/:email/organization/:organizationId/emailResetPasswordLink";
export const WEB_API_USER_GET_PROFILE = WEB_API_USER_BASE_PATH + "/:id/organization/:organizationId/profile";
export const WEB_API_USER_PATCH_NOTIFICATIONS = WEB_API_USER_BASE_PATH + "/:id/notifications";
export const WEB_API_USER_GET_LANDING_PAGE = WEB_API_USER_BASE_PATH + "/:id/landingPage?buildProfile=";
export const WEB_API_USER_GET_LOC_LANDING_PAGE = WEB_API_USER_BASE_PATH + "/:id/viaEmail/:viaEmail/landingPage?buildProfile=";
export const WEB_API_USER_SEND_EMAIL_VERIFICATION =
    WEB_API_USER_BASE_PATH + "/:userId/email/:email/organization/:organizationId/sendEmailVerification";
export const WEB_API_USER_SEND_EMAIL_VERIFICATION_ONE_TIME_PASSWORD = ONE_TIME_PASSWORD + WEB_API_USER_BASE_PATH + "/:userId/send-email-otp-verification";
export const WEB_API_USER_VERIFY_CODE_EMAIL_OTP_VERIFICATION = ONE_TIME_PASSWORD + WEB_API_USER_BASE_PATH + "/:userId/verify-code-email-otp-verification";
export const WEB_API_USER_VERIFY_EMAIL = WEB_API_USER_BASE_PATH + "/:userId/token/:token/verifyemail";
export const WEB_API_USER_VERIFY_RESET_PASSWORD_TOKEN =
    "/web" + WEB_API_USER_BASE_PATH + "/:userId/uniqueResetId/:token";
export const WEB_API_USER_SAVE_SUB_ID_TRACKING = WEB_API_USER_BASE_PATH + "/subidTracking";
export const WEB_API_USER_GET_SUB_ID_TRACKING = WEB_API_USER_BASE_PATH + "/:userId/organization/:organizationId/subidTracking";
export const WEB_API_USER_CHECK_VERIFICATION_STATUS = "one-time-password" + WEB_API_USER_BASE_PATH + "/:userId/phone-verification-status";
export const WEB_API_USER_SEND_VERIFICATION_CODE = "one-time-password" + WEB_API_USER_BASE_PATH + "/:userId/send-code";
export const WEB_API_USER_SEND_VERIFICATION_CODE_THROUGH_CALL = "one-time-password" + WEB_API_USER_BASE_PATH + "/:userId/make-call";
export const WEB_API_USER_SUBMIT_PHONE_VERIFICATION_CODE = "one-time-password" + WEB_API_USER_BASE_PATH + "/:userId/verify-code";
export const WEB_API_USER_SKIP_PHONE_VERIFICATION_CODE = WEB_API_USER_BASE_PATH + "/:userId/skip-phone-verification";
export const WEB_API_USER_ADD_PHONE_VERIFICATION_NOTE = "one-time-password" + WEB_API_USER_BASE_PATH + "/:userId/loan/:baseLoanId/phone-verification-note";
export const WEB_API_SEND_OTP_LOGIN_EMAIL = WEB_API_USER_BASE_PATH + "/login/email/verification-code";
export const WEB_API_SUBMIT_OTP_LOGIN_VERIFICATION_CODE = WEB_API_USER_BASE_PATH + "/login/email/verification-code-validation";


export const WEB_API_LOGIN_EMAIL = "/user/login/email";


// User Application Information controller
export const WEB_API_USERINFO_BASE_PATH = "/application";
export const WEB_API_USERINFO_UNIQUESSN_GLOBALLY = WEB_API_USERINFO_BASE_PATH + "/:userAppId/validate";
export const WEB_API_START_APPLICATION = WEB_API_USERINFO_BASE_PATH + "/start";
export const WEB_API_PRE_STAGE_HB_CHECK = WEB_API_USERINFO_BASE_PATH + "/preStageHBCheck";
export const WEB_API_POST_STAGE_HB_CHECK = WEB_API_USERINFO_BASE_PATH + "/postStageHBCheck";
export const WEB_API_RELOAN_HB_CHECK = WEB_API_USERINFO_BASE_PATH + "/reloanHBCheck";
export const WEB_API_USER_SAVE_APPLICATION = WEB_API_USERINFO_BASE_PATH + "/:userAppId";
export const WEB_API_USER_CREATENEWUSER = WEB_API_USERINFO_BASE_PATH + "/create/:userAppId";
export const VALIDATE_DM_APPLICATION_CODE = WEB_API_USERINFO_BASE_PATH + "/validateApplicationCode";
export const WEB_API_GET_CAMPAIGN_URL = WEB_API_USERINFO_BASE_PATH + "/getCampaignUrl";
export const WEB_API_UPDATE_SIN = WEB_API_USERINFO_BASE_PATH + "/reapply/:userAppId";

//Partner Integration
export const WEB_API_PARTNER_ON_BASE_PATH = "/partner-integration";
export const VALIDATE_PARTNER_PARAM = WEB_API_PARTNER_ON_BASE_PATH + "/organization/:organizationId/validate-partner-param";

// User Loan Application Information Controller
export const WEB_API_USERLOANINFO_BASE_PATH = "/loanapplication";
export const WEB_API_USERLOANINFO_UPDATE_PROGRESS = WEB_API_USERLOANINFO_BASE_PATH + "/user/:userId";
export const WEB_API_GET_BANK_VERIFICATION_CONFIRMED = WEB_API_USERLOANINFO_BASE_PATH + "/user/baseLoan/:baseLoanId/bank-verification-confirmed";

// Customer Controller
export const JAG_API_CUSTOMER_BASE_PATH = "/customer";
export const JAG_API_CUSTOMER_GET_EXTERNAL_APPLICATION_INFO =
    JAG_API_CUSTOMER_BASE_PATH + "/application/:customerId/organization/:organizationId";
export const JAG_API_CUSTOMER_SAVE_APPLICATION = JAG_API_CUSTOMER_BASE_PATH + "/:customerId";
export const JAG_API_CUSTOMER_ACTIVE_LOAN_SUMMARY =
    JAG_API_CUSTOMER_BASE_PATH + "/:customerId/currentActiveLoanSummary";
export const JAG_API_LATEST_NON_WITHDRAWN_LOAN_SUMMARY =
    JAG_API_CUSTOMER_BASE_PATH + "/:customerId/latestNonWithdrawnLoanSummary";
export const JAG_API_CUSTOMER_ACTIVE_LOAN = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/currentActiveLoan";
export const JAG_API_CUSTOMER_UPDATE_ACTIVE_LOAN_AMOUNT = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/activeLoanAmount";
export const JAG_API_CUSTOMER_UPDATE_LOAN_LIMIT_HISTORY = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/loanLimitHistory";
export const JAG_API_CUSTOMER_UPDATE_CONTACT_INFO = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/contactinfo";
export const JAG_API_CUSTOMER_UPDATE_INFO_AND_REEXPRESS = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/baseLoan/:baseLoanId/bankverification/updateCustomerInfoAndReExpress";
export const JAG_API_GET_PAYMENT_SCHEDULE =
JAG_API_CUSTOMER_BASE_PATH + "/:customerId/baseLoan/:baseLoanId/activePaymentSchedule";
export const JAG_API_CUSTOMER_INITIATE_DRAW = JAG_API_CUSTOMER_BASE_PATH + "/initiateDraw";
export const JAG_API_BANK_VERIFICATON_CHECKLIST = JAG_API_CUSTOMER_BASE_PATH + "/bankverification";
export const JAG_API_SAVE_REQUEST_DETAILS = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/organization/:organizationId/updateRequestDetails";
export const JAG_API_OUTSTANDING_BALANCE =
    JAG_API_CUSTOMER_BASE_PATH + "/:customerId/baseLoan/:baseLoanId/outstandingBalance";
export const JAG_API_IS_TX_IMPORT = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/:baseLoanId/isTXCustomerImported";
export const JAG_API_CUSTOMER_OTP_STATUS = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/one-time-password/status";
export const JAG_API_CUSTOMER_OTP_SEND_CODE = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/one-time-password/send-code";
export const JAG_API_CUSTOMER_OTP_VERIFY = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/one-time-password/verify";
export const JAG_API_CUSTOMER_GET_BANKING_INFORMATION_FOR_RC = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/getBankingInformationForRC";
export const JAG_API_CUSTOMER_GET_BANKING_INFORMATION = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/getBankingInformation";
export const JAG_API_CUSTOMER_CHECK_BV_DATA_READY = JAG_API_CUSTOMER_BASE_PATH + "/checkBVDataReady?bvRequestId=:bvRequestId&bvSystemId=:bvSystemId";
export const JAG_API_CUSTOMER_GET_POLL_PARAMS = JAG_API_CUSTOMER_BASE_PATH + "/:customerId/getPollParameters?organizationId=:organizationId&bvRequestId=:bvRequestId&bvSystemId=:bvSystemId";
export const JAG_API_CUSTOMER_CHECK_BV_CONN_STATUS = JAG_API_CUSTOMER_BASE_PATH + "/checkBvConnectionStatus?bvRequestId=:bvRequestId&bvSystemId=:bvSystemId";

// KYC Controller
export const JAG_API_KYC_BASE_PATH = "/kyc";
export const JAG_API_KYC_GET_KYC_QUESTIONS =
    JAG_API_KYC_BASE_PATH + "/questions/:leadSequenceId/remainingtime/:loanHeaderId";
export const JAG_API_KYC_ANSWERS = JAG_API_KYC_BASE_PATH + "/answers";
export const JAG_API_KYC_TIMEOUT = JAG_API_KYC_BASE_PATH + "/timeout/:loanHeaderId/:leadSequenceId";
export const JAG_API_KYC_REAPPLY_TIME = JAG_API_KYC_BASE_PATH + "/:loanHeaderId/kycRejectedTime";

// Organization State Controller
export const WEB_API_ORGANIZATION_STATE_BASE_PATH = "/organizationState";
export const WEB_API_ORGANIZATION_STATE_STATUS =
    WEB_API_ORGANIZATION_STATE_BASE_PATH + "/organization/:organizationId/state/:stateCode";
export const WEB_API_BRAND_STATE_STATUS =
    WEB_API_ORGANIZATION_STATE_BASE_PATH + "/brand/:brandName/state/:stateCode";
export const WEB_API_ORGANIZATION_STATE_LOAN_LIMITS =
    WEB_API_ORGANIZATION_STATE_BASE_PATH + "/organization/:organizationId/statecode/:stateCode/loanLimits";
// Organizaton State Config Controller
export const WEB_API_STATE_ORGANIZATION_CONFIG_BASE_PATH = "/state-organization-config";
export const WEB_API_STATE_ORGANIZATION_CONFIG =
    WEB_API_STATE_ORGANIZATION_CONFIG_BASE_PATH + "/organization/:organizationId/statecode/:stateCode";

// General Controller
export const API_GENERAL_BASE_PATH = "/misc";
export const API_VALIDATE_AND_EXTRACT_JWT_HB_TOKEN = API_GENERAL_BASE_PATH + "/validateAndExtractHBJWTToken";
export const API_VALIDATE_AND_EXTRACT_JWT_MKWEB_RESET_PASSWORD_TOKEN =
    API_GENERAL_BASE_PATH + "/validateAndExtractMKWebResetPasswordToken/:resetpasswordtoken";
export const API_ORGANIZATION_CONFIG = API_GENERAL_BASE_PATH + "/uiconfig/:organizationId";
export const API_IS_CHAT_ACTIVE = API_GENERAL_BASE_PATH + "/isChatActive";
export const API_GET_CHAT_HOURS = API_GENERAL_BASE_PATH + "/getCustomerCareHours";
export const API_SUBMIT_FEEDBACK = API_GENERAL_BASE_PATH + "/submitFeedback";
export const API_SUBMIT_DEBT_VALIDATION = API_GENERAL_BASE_PATH + "/submitDebtValidation";
export const API_SAVE_EXTERNAL_LEAD_QUERY_PARAMS = API_GENERAL_BASE_PATH + "/saveExternalLeadQueryParams";
export const API_SERVER_TIME = API_GENERAL_BASE_PATH + "/serverTime";
export const API_EMAIL_OPT_OUT = API_GENERAL_BASE_PATH + "/customer/:customerId/baseLoan/:baseLoanId/emailOptOut";

// Loan Controller
export const API_LOAN_BASE_PATH = "/loan";
export const LOAN_API_APPROVED_LOAN_LIMIT = API_LOAN_BASE_PATH + "/loanlimits";
export const LOAN_API_LOAN_LIMIT_RC = API_LOAN_BASE_PATH + "/loanlimitsrc";
export const LOAN_SUBMIT_FOR_AUTO_APPROVAL = API_LOAN_BASE_PATH + "/:baseLoanId/submitForAutoApproval";
export const LOAN_PAYMENT_INFO = API_LOAN_BASE_PATH + "/:baseLoanId/loanPaymentInfo";
export const LOAN_CHECK_DRAW_ELIGIBILITY =  "/:baseLoanId/checkDrawEligibility";
export const LOAN_SHOW_LOC_LOWER_FEE =  "/:baseLoanId/showLocLowerFee";
export const LOAN_SAVE_LOC_LOWER_FEE_POPUP_ACKNOWLEDGEMENT =  "/:baseLoanId/saveLocLowerFeePopupAcknowledgement";
export const LOAN_GET_LOC_FEE_LEVEL =  "/:baseLoanId/getFeeLevel";
export const LOAN_SAVE_DOCUMENT_VIEWED_ON_DASHBOARD =  "/saveDocumentViewedOnDashboard";
export const LOAN_API_DOCUMENT_UPLOAD = API_LOAN_BASE_PATH + "/:baseLoanId/documentUpload";
export const LOAN_API_CUSTOMER_CANCEL_DRAW = API_LOAN_BASE_PATH + "/:baseLoanId/cancelDraw";
// Jag Loans Controller
export const JAG_API_LOANS_BASE_PATH = "/loans";
export const JAG_API_LOANS_CHECKLIST = JAG_API_LOANS_BASE_PATH + "/:loanId/checklist";
export const LOAN_API_STATEMENTS = JAG_API_LOANS_BASE_PATH + "/:loanId/statements";
export const LOAN_API_STATEMENT_DETAILS = JAG_API_LOANS_BASE_PATH + "/:loanId/statements/:statementId";
export const LOAN_API_HISTORY = JAG_API_LOANS_BASE_PATH + "/user/:userId/history";
export const JAG_API_GET_ACH_DETAILS = JAG_API_LOANS_BASE_PATH + '/baseLoan/:baseLoanId/getAchDetails'
export const JAG_API_SAVE_SPOUSE_INFO = JAG_API_LOANS_BASE_PATH + '/:baseLoanId/spouseInfo'
export const JAG_API_VERIFY_EFFECTIVE_DATE = JAG_API_LOANS_BASE_PATH + '/baseLoan/:baseLoanId/verifyEffectiveDate'
export const JAG_API_REEXPRESS = JAG_API_LOANS_BASE_PATH + '/baseLoan/:baseLoanId/reexpress'
export const JAG_API_SAVE_FLINKS_EVENT = JAG_API_LOANS_BASE_PATH + "/:baseLoanId/flinksEvent";
export const JAG_API_STATEMENT_PDF = JAG_API_LOANS_BASE_PATH + "/:loanId/statements/:statementId/pdf";
export const WEB_API_LOAN_RETRIEVE_ANNUAL_INTEREST_RATE = JAG_API_LOANS_BASE_PATH + "/:baseLoanId/retrieveAnnualInterestRate";

// Loan Docs Controller
export const JAG_API_LOAN_DOCS_BASE_PATH = "/loandocs";
export const JAG_API_LOAN_DOCS_GET_BY_CUSTOMER =
    JAG_API_LOAN_DOCS_BASE_PATH + "/customer/:customerId/organization/:organizationId";
export const JAG_API_LOAN_DOCS_CONTENT_BY_DOCUMENT_VERSION_ID =
    JAG_API_LOAN_DOCS_BASE_PATH +
    "/:documentVersionId/baseLoan/:baseLoanId/customer/:customerId/organization/:organizationId/content";
export const JAG_API_LOAN_DOCS_SAVE_AGREEMENTS = JAG_API_LOAN_DOCS_BASE_PATH + "/agreements";
export const JAG_API_LOAN_DOCS_SIGNED_AGREEMENTS =
    JAG_API_LOAN_DOCS_BASE_PATH + "/user/:id/baseLoan/:loanId/agreements";
export const JAG_API_LOAN_DOCS_SIGNED_AGREEMENT_VERSION = "/loandocs/user/:id/baseLoan/agreements/signed-document/pdf";
export const JAG_API_LOAN_DOCS_SIGNED_AGREEMENT_STATE_VERSION =
    JAG_API_LOAN_DOCS_SIGNED_AGREEMENTS + "/archive/pdf";

// Consent Controller
export const WEB_API_CONSENT_BASE_PATH = "/consent";
export const WEB_API_GET_CONSENT_CONTENT = WEB_API_CONSENT_BASE_PATH + "/documents";
export const WEB_API_GET_DOC_NAME_BASED_CONTENT = WEB_API_CONSENT_BASE_PATH + "/documentName/content";
export const WEB_API_SAVE_CONSENT_CONTENT = WEB_API_CONSENT_BASE_PATH + "/saveSignedDocuments";

// Tracking Controller
export const WEB_API_TRACKING = "/tracking/activity";
export const WEB_API_TRACKING_NOTIFICATION = "/tracking/notification";

// Privacy Opt Out Controller
export const WEB_API_OPTOUT_BASE_PATH = "/privacyoptout";
export const WEB_API_OPTOUT_SAVE_PREFERENCES = WEB_API_OPTOUT_BASE_PATH + "/saveRequest";

//RAL Reapply Urls

export const RAL_REAPPLY_URL = WEB_API_USER_BASE_PATH + "/:userId/organization/:orgId/reapply";
export const RAL_VALIDATE_DM_CODE = "/user/:userId/validatePromoCode";

// Hotbox Controller
export const TRUST_ENV_API_URL = "/riskCall/addSession";
export const HOTBOX_API_REKYC = "/organization/:organizationId/baseLoan/:baseLoanId/leadsequence/:leadSequenceId/rekyc";
export const HOTBOX_OTP_PROCESSDATA = "/riskCall/baseLoan/:baseLoanId/processOTPData";
export const HOTBOX_PAYROLL_PROCESSDATA = "/riskCall/baseLoan/:baseLoanId/processPayrollData"

// Credit limit increase Urls.

export const WEB_API_ACCEPT_CREDIT_LIMIT_INCREASE =  "/operations/customer/:customerId/loan/:loanId/loc/creditincrease/accept";

//Online Payment API end points.

export const CARD_PAYMENT_ENABLED_URL = "/organizationState/organization/:orgId/statecode/:stateCode/cardPaymentEnabled";
export const GET_CURRENT_STATEMENT_BALANCE_URL = "/:baseLoanId/currentBalanceAndStatement";
export const CARD_PAYMENT_PREVIEW_URL = "/:baseLoanId/amount/:amount/cardPaymentPreview";
export const CARD_PAYMENT_URL = "/:baseLoanId/cardPayment";

//State selection api Url.
export const STATE_SELECTION_API_URL = "/organizationState/state/:state/organization?origin=";

// CaCustomerController
export const TDC_API_USER_BASE_PATH = "/tdc";
export const TDC_API_USER_GET_PROFILE = TDC_API_USER_BASE_PATH + "/caCustomer/:id";
export const TDC_API_REDIRECT_PAGE = TDC_API_USER_BASE_PATH + "/caCustomer/:id/redirectPage";

//Document Upload Enabled API endpoint
export const DOC_UPLOAD_ENABLED_URL = "/organizationState/organization/:orgId/statecode/:stateCode/isDocUploadEnabled";

//Onfido
export const ONFIDO_CREATE_SDK_URL = "/identity-verification/onfido/sdk/customer/:customerId/loan/:baseLoanId/create";
export const ONFIDO_SDK_EVENT_CALLBACK_URL = "/identity-verification/onfido/sdk/:sdkId/callback";
export const ONFIDO_SDK_EVENT_TRACKING_URL = "/identity-verification/onfido/sdk/:sdkId/loan/:baseLoanId"+WEB_API_TRACKING;
// One time URL endpoint
export const REPAY_PAYMENT_URL= "/repay/loan/:loanId/amount/:amount/paymentURL";
export const REPAY_PAYMENT_TRANSACTION_INFO_URL= "/repay/loan/:loanId/response-log/:logId/retrieve";
export const LPP_PAYMENT_TRANSACTION_INFO_URL= "/lpp/loan/:loanId/response-log/:logId/retrieve";
export const REPAY_TRACK_CARD_PAYMENT_PREVIEW= "/repay/loan/:loanId/response-log/:logId/card-payment-preview-tracking";
export const WEB_API_IS_ACCOUNT_ON_HOLD= JAG_API_CUSTOMER_BASE_PATH+"/:customerId/accountOnHold";

// MFA URL endpoint
export const PROFILE_VIEW_MFA_VERIFY_PASSWORD = "/dashboard/profile/verify-password";
export const WEB_API_USER_SUBMIT_PASSWORD_VERIFICATION = WEB_API_USER_BASE_PATH + "/:userId/verify-password";
export const WEB_API_SEND_MFA_VERIFICATION_CODE = WEB_API_USER_BASE_PATH + "/:userId/send-enable-mfa";
export const WEB_API_VERIFY_MFA_CODE = WEB_API_USER_BASE_PATH + "/:userId/verify-enable-mfa";
export const WEB_API_VERIFY_LOGIN_MFA_CODE = WEB_API_USER_BASE_PATH + "/mfa/validate";
export const WEB_API_RESEND_LOGIN_MFA_CODE = WEB_API_USER_BASE_PATH + "/mfa/:contactType/resend";
export const WEB_API_CHECK_IF_MFA_ENABLED = WEB_API_USER_BASE_PATH + "/:userId/is-mfa-enabled";
export const WEB_API_CHECK_IF_MFA_ENABLED_FOR_ORG = WEB_API_USER_BASE_PATH + "/:userId/is-mfa-enabled-for-state-organization";
export const WEB_API_CHECK_IF_MFA_SHOW_NOTIFICATION = WEB_API_USER_BASE_PATH + "/:userId/show-mfa-notification";
export const WEB_API_SKIP_MFA_NOTIFICATION_SCREEN = WEB_API_USER_BASE_PATH + "/:userId/skip-mfa-notification";
export const WEB_API_DISABLE_MFA = WEB_API_USER_BASE_PATH + "/:userId/disable-mfa";
export const PROFILE_MFA_SET_UP_PAGE = "/profile/mfa/setup";
export const LOGIN_MFA_SET_UP_PAGE = "/login/mfa/setup";
export const LOGIN_MFA_VERIFICATION_SMS_PAGE = "/login/mfa/sms";
export const LOGIN_MFA_VERIFICATION_EMAIL_PAGE = "/login/mfa/email";
export const PROFILE_MFA_VERIFICATION_PAGE = "/profile/mfa/setup-verify";
export const LOGIN_MFA_SET_UP_VERIFICATION_PAGE = "/login/mfa/setup-verify";
export const PROFILE_MFA_DISABLE_PAGE = "/dashboard/profile/mfa/disable";

//One Signal Endpoint
export const CREATE_ONE_SIGNAL_USER = WEB_API_USERINFO_BASE_PATH + "/createOneSignalUser/:userId/organization/:organizationId";

export const GET_ONE_SIGNAL_USER = WEB_API_USERINFO_BASE_PATH + "/getOneSignalUser/player/:playerId";

export  const ONE_SIGNAL_CREATE_PUSH_NOTIFICATION= "https://onesignal.com/api/v1/notifications";

//CEP
export const WEB_API_GET_CREDIT_EVOLUTION_STATUS= "/loan/:baseLoanId/credit-evolution-status";
export const WEB_API_GET_CREDIT_EVOLUTION_DETAIL= "/loan/:baseLoanId/credit-evolution-detail";
export const WEB_API_GET_PRICING_MODEL_DETAIL= "/loan/:baseLoanId/pricing-model-detail";
export const DASHBOARD_CREDIT_EVOLUTION = "credit-evolution-program";
export const DASHBOARD_CREDIT_EVOLUTION_DETAILS_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_CREDIT_EVOLUTION}`;

//Payment Protection Plan
export const DASHBOARD_PAYMENT_PROTECTION_PLAN = "payment-protection-plan";
export const DASHBOARD_PAYMENT_PROTECTION_PLAN_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_PAYMENT_PROTECTION_PLAN}`;
export const DASHBOARD_PAYMENT_PROTECTION_ACKNOWLEDGEMENT = "payment-protection-acknowledgement";
export const DASHBOARD_PAYMENT_PROTECTION_ACKNOWLEDGEMENT_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_PAYMENT_PROTECTION_ACKNOWLEDGEMENT}`;
export const DASHBOARD_PRE_AUTH_DEBIT_SUMMARY = "pre-auth-debit-summary";
export const DASHBOARD_PRE_AUTH_DEBIT_SUMMARY_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_PRE_AUTH_DEBIT_SUMMARY}`;
export const DASHBOARD_PRE_AUTH_DEBIT_SET_UP = "pre-auth-debit-set-up";
export const DASHBOARD_PRE_AUTH_DEBIT_SET_UP_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_PRE_AUTH_DEBIT_SET_UP}`;
export const DASHBOARD_PRE_AUTH_DEBIT_CONFIRMATION = "pre-auth-debit-confirmation";
export const DASHBOARD_PRE_AUTH_DEBIT_CONFIRMATION_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${DASHBOARD_PRE_AUTH_DEBIT_CONFIRMATION}`;
export const WEB_API_INSURANCE_BASE_PATH = "/insurance";
export const WEB_API_GET_INSURANCE_STATUS = WEB_API_INSURANCE_BASE_PATH + "/organization/:organizationId/statecode/:stateCode/baseLoan/:baseLoanId/insurance-status";
export const WEB_API_SUBMIT_INSURANCE_ACKNOWLEDGEMENT_ACTION = WEB_API_INSURANCE_BASE_PATH + "/protection-plan/acknowledgement";
export const WEB_API_APPLY_FOR_PAYMENT_PROTECTION_PLAN = WEB_API_INSURANCE_BASE_PATH + "/protection-plan/apply";
export const WEB_API_GET_PAYMENT_PROTECTION_PLAN_DETAILS = WEB_API_INSURANCE_BASE_PATH + "/:baseLoanId/protection-plan/details";
export const WEB_API_SHOW_INSURANCE_POPUP = WEB_API_INSURANCE_BASE_PATH + "/:userId/show-insurance-popup";
export const WEB_API_LOG_INSURANCE_POPUP_TIME = WEB_API_INSURANCE_BASE_PATH + "/:userId/log-insurance-popup-time";

//Standalone ACH
export const VIEW_ACH_AUTH_PAGE = "payment-authorization";
export const PROFILE_VIEW_ACH_AUTH_PAGE = `/${DASHBOARD_BASE_ROUTE_PARAMETER}/${VIEW_ACH_AUTH_PAGE}`;
export const WEB_API_GET_ACH_INFO = "/loan/:baseLoanId/get-ach-info";
export const WEB_API_GET_FILLED_DOCUMENT = "/loan/:baseLoanId/get-filled-document/:docGroup";
export const WEB_API_SUBMIT_SIGNED_DOCUMENT = "/loan/:baseLoanId/signed-document/:docGroup";

// LPP
export const LPP_PAYMENT_URL= "/lpp/loan/:loanId/amount/:amount/paymentURL";
export const LPP_TRACK_CARD_PAYMENT_PREVIEW= "/lpp/loan/:loanId/response-log/:logId/card-payment-preview-tracking";

// KOHO
export const WEB_API_REJECT_EVENT_UNSUPPORTED_STATE = WEB_API_USER_BASE_PATH + "/organization/:organizationId/reject-unsupported-state";
