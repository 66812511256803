

import React from "react";
import ReactDOM from "react-dom";
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import createSagaMiddleware from "redux-saga";
import {ConnectedRouter, connectRouter, routerMiddleware} from "connected-react-router";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore, persistReducer} from "redux-persist";

import {history} from "./_history";
import storage from "redux-persist/lib/storage";
import * as field from "./shared/constant/field";
import * as routeutility from "./shared/utility/routeutility";
import * as OrganizationActions from "./store/action/OrganizationActions";
import rootReducer, {fromCookie} from "./store/reducer";
import rootSaga from "./store/saga";
import App from "./views/App";
import {getIPAddressAsync} from "./shared/utility/ipify";

// Start getting the ip address in the backend as a first thing. This is very primary to majority of our operations.
getIPAddressAsync();

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// Rehydrate store state from cookie if needed.
// This is helpful in situation when user is in the middle of flow, and refreshed the page
// or closed the browser tab and coming back on the same page in another tab
const rehydrateStoreState = {
    [field.REDUCER_NODE_AUTH]: routeutility.isPageEligibleForRehydratingAuthState(history.location.pathname)
        ? fromCookie.loadAuthDatafromCookie()
        : undefined,
    [field.REDUCER_NODE_LOAN]: routeutility.isPageEligibleForRehydratingLoanState(history.location.pathname)
        ? fromCookie.loadLoanDataFromCookie()
        : undefined,
    [field.REDUCER_NODE_DASHBOARD]: routeutility.isPageEligibleForRehydratingDashboardState(history.location.pathname)
        ? fromCookie.loadDashboardDataFromCookie()
        : undefined,
    [field.REDUCER_NODE_INSURANCE]: routeutility.isPageEligibleForRehydratingInsuranceState(history.location.pathname)
        ? fromCookie.loadInsuranceDataFromCookie()
        : undefined
};

// 'trace' option allows for call stack tracing of actions
// 'maxAge' option sets the maximum number of actions to store in the redux devtools history
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, maxAge: 5000})
    : compose;

const persistConfig = {
    key: "root",
    storage,
    whitelist: [""],
    blacklist: ["form", "loan", "organization", "dashboard"]
};

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);
const connectedReducer = connectRouter(history)(rootReducer);
const persistedReducer = persistReducer(persistConfig, connectedReducer);

const store = createStore(persistedReducer, rehydrateStoreState, composeEnhancer(middleware));
//let persistedStore = persistStore(store);

sagaMiddleware.run(rootSaga);

store.dispatch(OrganizationActions.setOrganizationContent());

store.dispatch(OrganizationActions.populateOrganizationInitialConfig());
let persistedStore = persistStore(store);

const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
