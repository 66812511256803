import _ from "lodash";

import * as actionTypes from "../action/actionTypes";
import {DASHBOARD as field} from "../../shared/constant/field";
import * as constant from "../../shared/constant/constant";
import * as localStorageUtility from "../../shared/utility/localstorageutility";
import moment from "moment";
import * as tagConstants from "../../shared/constant/tagConstants";
import * as actionItemsHelper from "../../views/dashboard/myaccount/components/accountstatus/pending/components/actionitems/actionItemsHelper";
import * as utility from "../../shared/utility/utility";
import {setDataTags} from "../../views/OneSignal/PushNotification";
import { submitSignedDocumentSuccess } from "../action/DashboardAction";

const initialState = {
    [field.PERSONAL_INFO]: {
        [field.FIRSTNAME]: "",
        [field.LASTNAME]: "",
        [field.EMAIL]: "",
        [field.SSN]: "",
        [field.HOME_PHONE]: "",
        [field.CELL_PHONE]: "",
        [field.ADDRESS]: "",
        [field.ADDRESS2]: "",
        [field.CITY]: "",
        [field.STATE]: "",
        [field.ZIP]: ""
    },
    [field.COMMUNICATION_PREFS]: {
        [field.COMM_TEXT_MESSAGES]: false,
        [field.COMM_PROMO_MESSAGES]: false,
        [field.COMM_PROMO_CALLS]: false
    },
    [field.VERIFIED]: null,
    [field.CURRENT_LOGIN_TIME]: "1999-01-01",
    [field.ACCOUNT]: {
        [field.CUSTOMER_ID]: null
    },
    [field.STATEMENTS]: [],
    [field.HISTORY]: [],
    [field.SELECTED_STATEMENT_CONTENT]: {},
    [field.AGREEMENTS]: [],
    [field.SELECTED_AGREEMENT_CONTENT]: {},
    [field.ACTION_ITEMS]: {
        [field.TODO_BANK_VERIFICATION]: null,
        [field.TODO_EMPLOYMENT_VERIFICATION]: null,
        [field.TODO_REFERENCES]: null,
        [field.TODO_ESIG]: null,
        [field.TODO_COLLECT_PAYMENT_INFO]: null,
        [field.TODO_SPOUSAL_INFORMATION]: null,
        [field.TODO_EMAIL_VERIFICATION]: null,
        [field.TODO_ACCOUNT_OWNERSHIP]: null,
        [field.TODO_ACCOUNT_NUMBER]: null
    },
    [field.BANK_VERIFICATION_ITEMS]: {
        [field.BANK_VERIFICATION_STATUS]: false,
        [field.DECISION_LOGIC_URL]: null,
        [field.SHOW_VERIFY_MY_ACCOUNT_BUTTON]: false,
        [field.BV_SYSTEM_ID]: null,
        [field.BV_SYSTEM_NAME]: null,
        [field.BV_SYSTEM_URL]: null
    },
    [field.SPOUSE_INFO]: {
        [field.MARITAL_STATUS]: null,
        [field.SPOUSE_FIRST_NAME]: null,
        [field.SPOUSE_LAST_NAME]: null,
        [field.SPOUSE_EMAIL]: null,
        [field.SPOUSE_ADDRESS_1]: null,
        [field.SPOUSE_ADDRESS_2]: null,
        [field.SPOUSE_CITY]: null,
        [field.SPOUSE_STATE]: null,
        [field.SPOUSE_ZIP_CODE]: null
    },
    [field.USEROTPINFO]: {
        [field.ISOTPENABLED]: null,
        [field.ISOTPSENTPOSTLOGIN]: false,
        [field.PHONENUMBER]: null,
        [field.OTPSTATUS]: null,
        [field.SMSSENTDATETIME]: null,
        [field.SENDOTPCODECLICKEDTIME]: null
    },
    [field.OTP_PROCESSDATA]: {
        [field.OTP_PROCESSDATA_RESULT]: null,
        [field.OTP_PROCESSDATA_IS_AUTOFUND]: null,
        [field.OTP_PROCESSDATA_IS_NBC]: null
    },
    [field.PAYROLL_PROCESSDATA]: {
        [field.PAYROLL_PROCESSDATA_RESULT]: null,
        [field.PAYROLL_PROCESSDATA_IS_AUTOFUND]: null,
        [field.PAYROLL_PROCESSDATA_IS_NBC]: null,
        [field.PAYROLL_PROCESSDATA_PRIMARY_AMOUNT]: null,
        [field.PAYROLL_PROCESSDATA_EMPLOYER_NAME]: null,
        [field.PAYROLL_PROCESSDATA_FULL_NMI]: null,
        [field.PAYROLL_PROCESSDATA_PRIMARY_FREQUENCY]: null,
        [field.PAYROLL_PROCESSDATA_IS_REJECTED]: null
    },
    [field.DRAW_ELIGIBILITY_INFO]: {
        [field.DRAW_ELIGIBILITY_IS_BANK_ACCOUNT_INFO_UPDATED]: null,
        [field.DRAW_ELIGIBILITY_IS_ACTIVE]: null,
        [field.DRAW_ELIGIBILITY_IS_ONLINE_DRAW_ENABLED]: null
    },
    [field.IS_TX_EPIC_IMPORT]: null,
    [field.LOC_OFFER_DETAILS]: {
        [field.OFFERED_INCREASE]: null,
        [field.OFFERED_LIMIT]: null,
        [field.LIMIT_INCREASE_STATUS]: null
    },
    [field.UNUSED_OFFER_LINK]: true,
    [field.ONLINE_PAYMENT_DETAILS]: {
        [field.FULL_NAME]: null,
        [field.PAYMENT_CARD_NUMBER]: null,
        [field.CARD_ZIP_CODE]: null,
        [field.CARD_CVV_NUMBER]: null,
        [field.CARD_EXPIRY_DATE]: null,
        [field.ONLINE_PAYMENT_AMOUNT]: null
    },
    [field.PAYMENT_ENABLED]: {
        [field.CARD_PAYMENT_ENABLED]: null,
        [field.IS_LPP_IFRAME_SUPPORTED]: null,
        [field.CARD_PAYMENT_ENABLED_STATUS]: null
    },
    [field.CURRENT_BALANCE_AND_STATEMENT]: {
        [field.CURRENT_BALANCE]: null,
        [field.PAST_DUE]: null,
        [field.CARD_PAYMENT_DUE_DATE]: null
    },
    [field.CARD_PAYMENT_PREVIEW]: {
        [field.CARD_PAYMENT_FEES]: null,
        [field.CARD_PAYMENT_INTEREST]: null,
        [field.CARD_PAYMENT_OUTSTANDING_BALANCE]: null,
        [field.CARD_PAYMENT_PAST_DUE]: null,
        [field.CARD_PAYMENT_PRINCIPAL]: null,
        [field.CARD_PAYMENT_TRANSACTIONS]: {
            [field.CARD_TRANSACTION_DETAILS_INDEX_0]: {
                [field.CARD_PAYMENT_PRINCIPAL]: null,
                [field.CARD_PAYMENT_FEES]: null,
                [field.CARD_PAYMENT_INTEREST]: null,
                [field.CARD_PAYMENT_PAST_DUE]: null
            },
            [field.CARD_TRANSACTION_DETAILS_INDEX_1]: {
                [field.CARD_PAYMENT_PRINCIPAL]: null,
                [field.CARD_PAYMENT_FEES]: null,
                [field.CARD_PAYMENT_INTEREST]: null,
                [field.CARD_PAYMENT_PAST_DUE]: null
            }
        }
    },
    [field.CARD_PAYMENT_DETAILS]: {
        [field.CARD_TRANSACTION_DETAILS]: {
            [field.CARD_PAYMENT_AUTHORIZATION_CODE]: null,
            [field.CARD_PAYMENT_LAST_FOUR_DIGITS]: null,
            [field.CARD_PAYMENT_AMOUNT]: null,
            [field.CARD_PAYMENT_PROCESSING_DATE]: null,
            [field.CARD_PAYMENT_SUCCESS]: null,
            [field.CARD_PAYMENT_TRANSACTION_ID]: null
        },
        [field.CARD_PAYMENT_LOAN_DATA]: {
            [field.CARD_PAYMENT_FEES]: null,
            [field.CARD_PAYMENT_INTEREST]: null,
            [field.CARD_PAYMENT_OUTSTANDING_BALANCE]: null,
            [field.CARD_PAYMENT_PAST_DUE]: null,
            [field.CARD_PAYMENT_PRINCIPAL]: null
        },
        [field.CARD_PAYMENT_TRANSACTIONS]: {
            [field.CARD_TRANSACTION_DETAILS_INDEX_0]: {
                [field.CARD_TRANSACTION_DETAILS]: {
                    [field.CARD_PAYMENT_AUTHORIZATION_CODE]: null,
                    [field.CARD_PAYMENT_LAST_FOUR_DIGITS]: null,
                    [field.CARD_PAYMENT_AMOUNT]: null,
                    [field.CARD_PAYMENT_PROCESSING_DATE]: null,
                    [field.CARD_PAYMENT_SUCCESS]: null,
                    [field.CARD_PAYMENT_TRANSACTION_ID]: null
                },
                [field.CARD_PAYMENT_FEES]: null,
                [field.CARD_PAYMENT_INTEREST]: null,
                [field.CARD_PAYMENT_PAST_DUE]: null,
                [field.CARD_PAYMENT_PRINCIPAL]: null
            },
            [field.CARD_TRANSACTION_DETAILS_INDEX_1]: {
                [field.CARD_TRANSACTION_DETAILS]: {
                    [field.CARD_PAYMENT_AUTHORIZATION_CODE]: null,
                    [field.CARD_PAYMENT_LAST_FOUR_DIGITS]: null,
                    [field.CARD_PAYMENT_AMOUNT]: null,
                    [field.CARD_PAYMENT_PROCESSING_DATE]: null,
                    [field.CARD_PAYMENT_SUCCESS]: null,
                    [field.CARD_PAYMENT_TRANSACTION_ID]: null
                },
                [field.CARD_PAYMENT_FEES]: null,
                [field.CARD_PAYMENT_INTEREST]: null,
                [field.CARD_PAYMENT_PAST_DUE]: null,
                [field.CARD_PAYMENT_PRINCIPAL]: null
            }
        }
    },
    [field.CREDIT_PAYMENT_BATCH_SCHEDULE]: {
        [field.ACH_EFFECTIVE_DATE]: null,
        [field.IS_SAME_DAY_ACH]: null,
        [field.BATCH_DATE]: null,
        [field.BATCH_TIME]: null,
        [field.LASTEST_SAME_DAY_ACH_TIME_ON_EFFECTIVE_DATE]: null
    },
    [field.SHOW_LOADER]: false,
    [field.SHOW_ERROR]: false,
    [field.SHOW_LOC_LOWER_FEE_POPUP]: false,
    [field.CREDIT_EVOLUTION_STATUS]: {
        [field.CREDIT_EVOLUTION_APPLICABLE]: false,
        [field.CREDIT_EVOLUTION_ENROLLED]: false,
        [field.CREDIT_EVOLUTION_PRE_AUTH_PAYMENT]: false,
        [field.CREDIT_EVOLUTION_REACHED_TOP_CHARGE_TABLE]: false,
        [field.BANKING_INFO_BANK_NAME]: null,
        [field.CURRENT_PRICING_MODEL]:null,
        [field.ESTIMATED_EVOLUTION_DATE]:null,
        [field.CURRENT_SAVING_PERCENTAGE]:null,
        [field.CHARGE_TABLE]:[],
        [field.CREDIT_EVOLUTION_DETAILS_RESPONSE]: null
    },
    [field.PRICING_MODEL_DETAILS]: {
        [field.CURRENT_ENTITY]: null,
        [field.MONTHLY_ENTITY]:null,
        [field.PRICING_MODEL_CHARGE_TABLE_ITEMS]: []
    },
    [field.ACH_INFO]: {
        [field.STANDALONE_ACH_SUPPORTED]: null,
        [field.ACH_ENABLED]: null,
        [field.ENABLE_ACH_AUTH]: null
    }
};

const _setDashboardReducerCookie = state => {
    _setDashboardReducerInLocalStorage(state);
    // We are experimenting to move with LS. So redirecting requests to store data in LS in above line.
    // let cookieMkwebDashboardData = _.cloneDeep(state);
    // cookieUtility.setCookie(constant.COOKIE_MKWEBDASHBOARD_DATA, JSON.stringify(cookieMkwebDashboardData));
};
const _setCompleteApplicationTodoTags = state => {
    let todoItems = {};
    if (state.actionItems) {
        todoItems[tagConstants.TAG_BANK_VERIFICATION_COMPLETED] = !!state.actionItems[field.TODO_BANK_VERIFICATION] ?
            !actionItemsHelper.isBankVerificationRemaining(state.actionItems) : null;
        todoItems[tagConstants.TAG_ESIGN_COMPLETED] = !!state.actionItems[field.TODO_ESIG] ?
            !actionItemsHelper.isESigRemaining(state.actionItems) : null;
        todoItems[tagConstants.TAG_EMAIL_VERIFICATION_COMPLETED] = !!state.actionItems[field.TODO_EMAIL_VERIFICATION] ?
            !actionItemsHelper.isEmailVerificationRemaining(state.actionItems) : null;
        todoItems[tagConstants.TAG_IDENTITY_VERIFICATION_COMPLETED] = !!state.actionItems[field.TODO_IDENTITY_VERIFICATION] ?
            actionItemsHelper.isIdentityVerificationCompleted(state.actionItems) : null;
        todoItems[tagConstants.IS_KYC] = !!state.actionItems[field.TODO_KYC] ?
            actionItemsHelper.isKYCCompleted(state.actionItems) : null;
        todoItems[tagConstants.SPOUSE_INFO_COLLECTED] = !!state.actionItems[field.TODO_SPOUSAL_INFORMATION] ?
            !actionItemsHelper.isSpousalInformationRemaining(state.actionItems) : null;
        todoItems[tagConstants.TAG_PAYMENT_INFO_COLLECTED] = !!state.actionItems[field.TODO_COLLECT_PAYMENT_INFO] ?
            actionItemsHelper.isCollectPaymentInfo(state.actionItems) : null;
        todoItems[tagConstants.TAG_ACCOUNT_OWNERSHIP_COMPLETED] = !!state.actionItems[field.TODO_ACCOUNT_OWNERSHIP] ?
            !actionItemsHelper.isActionItemRemaining(state.actionItems[field.TODO_ACCOUNT_OWNERSHIP]) : null;
        todoItems[tagConstants.TAG_ACCOUNT_NUMBER_COMPLETED] = !!state.actionItems[field.TODO_ACCOUNT_NUMBER] ?
            !actionItemsHelper.isActionItemRemaining(state.actionItems[field.TODO_ACCOUNT_NUMBER]) : null;
    }
    todoItems[tagConstants.TAG_APPLICATION_COMPLETED] = true;
    todoItems[tagConstants.TAG_FIRST_NAME] = state.personalInfo.firstname;
    todoItems[tagConstants.TAG_LAST_NAME] = state.personalInfo.lastname;
    todoItems[tagConstants.TAG_ORGANIZATION_ID] = utility.getOrganizationId();
    setDataTags(todoItems);
}

const _setDashboardReducerInLocalStorage = state => {
    let lsMkwebDashboardData = _.cloneDeep(state);
    localStorageUtility.setLocalStorageValue(constant.COOKIE_MKWEBDASHBOARD_DATA, JSON.stringify(lsMkwebDashboardData));
};

const resetDashboardReducer = (state, action) => {
    let updatedState = _.cloneDeep(initialState);

    return updatedState;
};

export const loadDashboardDataFromCookie = () => {
    return loadDashboardDataFromLocalStorage();
    // We are experimenting to move with LS. So redirecting requests to store data in LS in above line.
    /*
    let dashboardState = initialState;
    let mkWebDashboardData = cookieUtility.getCookie(constant.COOKIE_MKWEBDASHBOARD_DATA);
    dashboardState = {...dashboardState, ...mkWebDashboardData};
    return dashboardState;
     */
};

export const loadDashboardDataFromLocalStorage = () => {
    let dashboardState = initialState;
    let mkWebDashboardData = JSON.parse(localStorageUtility.getLcalStorageValue(constant.COOKIE_MKWEBDASHBOARD_DATA));
    dashboardState = {...dashboardState, ...mkWebDashboardData};
    return dashboardState;
};

const _updateUserOTPInfo = (state, data) => {
    return {
        ...state,
        ...{
            [field.USEROTPINFO]: {...state[field.USEROTPINFO], ...data}
        }
    };
};

const _updateOTPProcessData = (state, data) => {
    return {
        ...state,
        ...{
            [field.OTP_PROCESSDATA]: {...state[field.OTP_PROCESSDATA], ...data}
        }
    };
};

const _updatePayrollProcessData = (state, data) => {
    return {
        ...state,
        ...{
            [field.PAYROLL_PROCESSDATA]: {...state[field.PAYROLL_PROCESSDATA], ...data}
        }
    };
};

const _updateDrawEligibilityData = (state, data) => {
    return {
        ...state,
        ...{
            [field.DRAW_ELIGIBILITY_INFO]: {...state[field.DRAW_ELIGIBILITY_INFO], ...data}
        }
    };
};

export const updateCommPreferences = (state, action) => {
    let updatedState = {
        ...state,
        [field.COMMUNICATION_PREFS]: action.payload.data.isMobile
            ? {
                  ...state[field.COMMUNICATION_PREFS],
                  [field.COMM_PROMO_MESSAGES]: action.payload.data.receiveOffers,
                  [field.COMM_PROMO_CALLS]: action.payload.data.receiveOffers,
                  [field.COMM_TEXT_MESSAGES]: true
            }
            : {
                  ...state[field.COMMUNICATION_PREFS],
                  [field.COMM_PROMO_CALLS]: action.payload.data.receiveOffers
              },
        [field.PERSONAL_INFO]: action.payload.data.isMobile
            ? {
                  ...state[field.PERSONAL_INFO],
                  [field.CELL_PHONE]: action.payload.data.contactNumber
              }
            : {
                  ...state[field.PERSONAL_INFO],
                  [field.HOME_PHONE]: action.payload.data.contactNumber
              }
    };
    return updatedState;
};
const _updateBankVerificationItems = (state, data) => {
    return {
        ...state,
        ...{
            [field.BANK_VERIFICATION_ITEMS]: {...state[field.BANK_VERIFICATION_ITEMS], ...data}
        }
    };
};

const _updateOnfidoData = (state, data) => {
    return {
        ...state, ...{
            [field.ONFIDO]: {...state[field.ONFIDO], ...data}
        }
    };
}

const getCreditEvolutionStatusSuccess = (state, data) => {
    return {
        ...state, ...{
            [field.CREDIT_EVOLUTION_STATUS]: {...state[field.CREDIT_EVOLUTION_STATUS], ...data}
        }
    };
}

const getCreditEvolutionDetailSuccess = (state, data) => {

    let updatedState = {
        ...state,
            [field.CREDIT_EVOLUTION_STATUS]: {
                ...state[field.CREDIT_EVOLUTION_STATUS],
                [field.BANKING_INFO_BANK_NAME]: data.bankName,
                [field.CURRENT_PRICING_MODEL]: data.currentPricingModel,
                [field.ESTIMATED_EVOLUTION_DATE]: data.estimatedEvolutionDate,
                [field.CURRENT_SAVING_PERCENTAGE]: data.currentSavingPercentage,
                [field.CHARGE_TABLE]: data.chargeTables,
                [field.CREDIT_EVOLUTION_DETAILS_RESPONSE]: new Date().getTime()
            }
    }
    return updatedState;
}

const getPricingModelDetailsSuccess = (state, data) => {

    let updatedState = {
        ...state,
        [field.PRICING_MODEL_DETAILS]: {
            ...state[field.PRICING_MODEL_DETAILS],
            [field.CURRENT_ENTITY]: data.currentEntity,
            [field.MONTHLY_ENTITY]: data.monthlyEntityId,
            [field.PRICING_MODEL_CHARGE_TABLE_ITEMS]: data.chargeTableItems
        }
    }
    return updatedState;
}

const getAchInfoSuccess = (state, data) => {
    let updatedState = {
        ...state,
            [field.ACH_INFO]: {
                ...state[field.ACH_INFO],
                [field.STANDALONE_ACH_SUPPORTED]: data.standaloneAchSupported,
                [field.ACH_ENABLED]: data.achEnabled,
                [field.ENABLE_ACH_AUTH]: data.enableAchAuth
            }
    }
    return updatedState;
}

const getFilledDocumentSuccess = (state, data) => {
    let updatedState = {
        ...state,
            [field.FILLED_DOCUMENT]: {
                ...state[field.FILLED_DOCUMENT],
                [field.DOCUMENT_ID]: data.documentId,
                [field.DOCUMENT_VERSION_ID]: data.documentVersionId,
                [field.SIGNATURE_REQUIRED]: data.signatureRequired,
                [field.EXPECTED_SIGNATURE]: data.expectedSignature,
                [field.SUBJECT]: data.subject,
                [field.DOC_CONTENT]: data.content
            }
    }
    return updatedState;
}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_DASHBOARD_REDUCER:
            return resetDashboardReducer(state, action);
        case actionTypes.GET_TDC_PERSONAL_INFO_SUCCESS:
            let personalInfo = action.payload.response.data;
            state = {
                ...state,
                [field.PERSONAL_INFO]: {
                    [field.FIRSTNAME]: personalInfo.firstName,
                    [field.LASTNAME]: personalInfo.lastName,
                    [field.EMAIL]: personalInfo.email,
                    [field.SSN]: personalInfo.ssn
                }
            };
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_PERSONAL_INFO_SUCCESS:
            let {data} = action.payload.response;
            state = {
                ...state,
                [field.PERSONAL_INFO]: {
                    [field.FIRSTNAME]: data.firstName,
                    [field.LASTNAME]: data.lastName,
                    [field.EMAIL]: data.email,
                    [field.HOME_PHONE]: data.homePhone,
                    [field.CELL_PHONE]: data.cellPhone,
                    [field.ADDRESS]: data.address,
                    [field.ADDRESS2]: data.address2,
                    [field.CITY]: data.city,
                    [field.STATE]: data.state,
                    [field.ZIP]: data.zip
                },
                [field.COMMUNICATION_PREFS]: {
                    [field.COMM_TEXT_MESSAGES]: data.smsTransactionalStatus ? true : false,
                    [field.COMM_PROMO_MESSAGES]: data.smsMarketingStatus ? true : false,
                    [field.COMM_PROMO_CALLS]: data.phoneMarketingStatus ? true : false
                },
                [field.CURRENT_LOGIN_TIME]: new Date(data.lastVisited).toLocaleString(),
                [field.VERIFIED]: data.verified ? true : false,
                [field.ACCOUNT]: {
                    ...state[field.ACCOUNT],
                    [field.CUSTOMER_ID]: data.customerId
                }
            };
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.SET_COMMUNICATION_PREFS:
            state = {...state, [field.COMMUNICATION_PREFS]: action.payload};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.SAVE_COMMUNICATION_PREFS_SUCCESS:
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_STATEMENTS_SUCCESS:
            state = {...state, [field.STATEMENTS]: action.payload.response.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_BANK_VERIFICATION_CONFIRMED_SUCCESS:
            state = _updateBankVerificationItems(state, {
                [field.IS_BANK_VERIFICATION_CONFIRMED]: action.payload.response.data
            });
            return state;
        case actionTypes.GET_LANDING_PAGE_SUCCESS:
            state = action.payload.response.data.locOfferDetails
                ? {
                      ...state,
                      [field.LOC_OFFER_DETAILS]: {
                          [field.OFFERED_INCREASE]: action.payload.response.data.locOfferDetails.offeredIncrease,
                          [field.OFFERED_LIMIT]: action.payload.response.data.locOfferDetails.offeredLimit,
                          [field.LIMIT_INCREASE_STATUS]: action.payload.response.data.locOfferDetails.status
                      }
                  }
                : state;
            return state;
        case actionTypes.GET_STATEMENT_DETAILS_SUCCESS:
            state = {...state, [field.SELECTED_STATEMENT_CONTENT]: action.payload.response.data};
            return state;
        case actionTypes.GET_SIGNED_AGREEMENTS_SUCCESS:
            state = {...state, [field.AGREEMENTS]: action.payload.response.data.agreements};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_AGREEMENT_DETAILS_SUCCESS:
            state = {...state, [field.SELECTED_AGREEMENT_CONTENT]: action.payload.response.data};
            return state;
        case actionTypes.RESET_AGREEMENT_DETAILS:
            state = {...state, [field.SELECTED_AGREEMENT_CONTENT]: {}};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_ACTION_ITEMS_SUCCESS:
            state = {...state, [field.ACTION_ITEMS]: action.payload.result};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.SAVE_TAGS_ON_ACTION_ITEMS_SUCCESS:
            state = {...state, [field.ACTION_ITEMS]: action.payload.result};
            _setCompleteApplicationTodoTags(state);
            return state;
        case actionTypes.SAVE_SPOUSAL_INFO_SUCCESS:
            state = {...state, [field.SPOUSE_INFO]: action.payload.response.data.spouseInfo};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_BANK_VERIFICATION_ITEMS_SUCCESS:
            state = _updateBankVerificationItems(state, action.payload.result);
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.RESET_BV_CHECK:
            state = {...state, [field.BV_DATA_POLL_PARAMS]: null};
            state = {...state, [field.BV_DATA_READY_RESULT]: null};
            state = {...state, [field.PAYROLL_PROCESSDATA]: {}};
            return state;
        case actionTypes.CHECK_BV_DATA_READY_SUCCESS:
            state = {...state, [field.BV_DATA_READY_RESULT]: action.payload.result};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.CHECK_BV_DATA_READY_FAILURE:
            state = {...state, [field.BV_DATA_READY_RESULT]: {isBVDataReady: false}};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_POLL_PARAMS_SUCCESS:
            state = {...state, [field.BV_DATA_POLL_PARAMS]: action.payload.result};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_ACH_DETAILS_SUCCESS:
            state = {...state, [field.CREDIT_PAYMENT_BATCH_SCHEDULE]: action.payload.response.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_HISTORY_SUCCESS:
            state = {...state, [field.HISTORY]: action.payload.response.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.IS_CUSTOMER_TX_EPIC_IMPORT_SUCCESS:
            state = {...state, [field.IS_TX_EPIC_IMPORT]: action.payload.result.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.CLEAR_SELECTED_STATEMENT:
            state = {...state, [field.SELECTED_STATEMENT_CONTENT]: initialState[field.SELECTED_STATEMENT_CONTENT]};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.CLEAR_STATEMENTS:
            state = {...state, [field.STATEMENTS]: initialState[field.STATEMENTS]};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.SET_OFFER_LINK_FLAG:
            state = {...state, [field.UNUSED_OFFER_LINK]: action.payload.data};
            return state;
        case actionTypes.UPDATE_COMM_PREFERENCE:
            return updateCommPreferences(state, action);
        case actionTypes.PAYMENT_CARD_ENABLED_SUCCESS:
            state = {...state, [field.PAYMENT_ENABLED]: action.payload.data.data};
            return state;
        case actionTypes.SAVE_ONLINE_PAYMENT_DETAILS:
            state = {...state, [field.ONLINE_PAYMENT_DETAILS]: action.payload.data};
            return state;
        case actionTypes.GET_STATEMENT_BALANCE_SUCCESS:
            state = {...state, [field.CURRENT_BALANCE_AND_STATEMENT]: action.payload.data.data};
            return state;
        case actionTypes.GET_CARD_PAYMENT_PREVIEW_SUCCESS:
            state = {...state, [field.CARD_PAYMENT_PREVIEW]: action.payload.data.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.GET_PAYMENT_URL_SUCCESS:
            state = {...state, [field.PAYMENT_REPAY]: action.payload.data.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.SUBMIT_CARD_PAYMENT_SUCCESS:
            state = {...state, [field.CARD_PAYMENT_DETAILS]: action.payload.data.data};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.RESET_PAYMENT_URL:
            state = {...state, [field.PAYMENT_REPAY]: null};
            state = {...state, [field.PAYMENT_LPP]: null};
            _setDashboardReducerCookie(state);
            return state;
        case actionTypes.SET_SHOW_LOADER:
            state = {...state, [field.SHOW_LOADER]: true};
            return state;
        case actionTypes.UNSET_SHOW_LOADER:
            state = {...state, [field.SHOW_LOADER]: false};
            return state;
        case actionTypes.SET_SHOW_ERROR:
            state = {...state, [field.SHOW_ERROR]: true};
            return state;
        case actionTypes.UNSET_SHOW_ERROR:
            state = {...state, [field.SHOW_ERROR]: false};
            return state;
        case actionTypes.SET_FLINKS_LOGIN_SUCCESS:
            state = {...state, [field.FLINKS_LOGIN_SUCCESS]: action.payload};
            return state;
        case actionTypes.SET_FLINKS_LOGIN_FAILURE:
            state = {...state, [field.FLINKS_LOGIN_FAILURE]: action.payload};
            return state;
        case actionTypes.SAVE_REQUEST_DETAILS_SUCCESS:
            state = {
                ...state,
                [field.REQUEST_DETAILS]: {
                    [field.REQUEST_DETAILS_STATUS]: "Success"
                }
            };
            return state;
        case actionTypes.SAVE_REQUEST_DETAILS_FAILURE:
            state = {...state, [field.REQUEST_DETAILS]: {[field.REQUEST_DETAILS_STATUS]: "Failure"}};
            return state;
        case actionTypes.DOCUMENT_UPLOAD_ENABLED_SUCCESS:
            state = {...state, [field.DOCUMENT_UPLOAD_ENABLED]: action.payload.data.data.docUploadEnabled};
            return state;
        case actionTypes.OTP_GET_STATUS_SUCCESS:
        case actionTypes.OTP_VERIFY_CODE_SUCCESS:
            return _updateUserOTPInfo(state, action.payload.response.data);
        case actionTypes.OTP_SEND_CODE_SUCCESS:
            return _updateUserOTPInfo(state, {
                ...action.payload.response.data,
                [field.ISOTPSENTPOSTLOGIN]: true,
                [field.SENDOTPCODECLICKEDTIME]: moment().format("YYYY-MM-DD HH:mm:ss")
            });
        case actionTypes.OTP_PROCESS_DATA_SUCCESS:
            return _updateOTPProcessData(state, action.payload.response.data);
        case actionTypes.PAYROLL_PROCESS_DATA_SUCCESS:
            return _updatePayrollProcessData(state, action.payload.response.data);
        case actionTypes.CHECK_DRAW_ELIGIBILITY_SUCCESS:
            return _updateDrawEligibilityData(state, action.payload.response.data);
        case actionTypes.SHOW_LOC_LOWER_FEE_SUCCESS:
            state = {...state, [field.SHOW_LOC_LOWER_FEE_POPUP]: action.payload.response.data};
            return state;
        case actionTypes.CREATE_ONFIDO_SDK_SUCCESS:
            return _updateOnfidoData(state, action.payload.data);
        case actionTypes.ONFIDO_SDK_EVENT_SUCCESS:
            return _updateOnfidoData(state, action.payload.data);

        case actionTypes.CREDIT_EVOLUTION_STATUS_SUCCESS:
            _setDashboardReducerCookie(state);
            return getCreditEvolutionStatusSuccess(state, action.payload.response.data);

        case actionTypes.CREDIT_EVOLUTION_DETAIL_SUCCESS:
            _setDashboardReducerCookie(state);
            return getCreditEvolutionDetailSuccess(state, action.payload.response.data);
        case actionTypes.PRICING_MODEL_DETAILS_SUCCESS:
            return getPricingModelDetailsSuccess(state, action.payload.response.data);

        case actionTypes.SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT_SUCCESS:
        case actionTypes.IS_ACCOUNT_ON_HOLD_SUCCESS:
            state = {...state, [field.IS_ACCOUNT_ON_HOLD]: action.payload.data.data.hold};
            return state;
        case actionTypes.GET_ACH_INFO_SUCCESS:
            _setDashboardReducerCookie(state);
            return getAchInfoSuccess(state, action.payload.response.data);
        case actionTypes.GET_FILLED_DOCUMENT_SUCCESS:
            _setDashboardReducerCookie(state);
            return getFilledDocumentSuccess(state, action.payload.response.data);
        case actionTypes.SUBMIT_SIGNED_DOCUMENT_SUCCESS:
            _setDashboardReducerCookie(state);
            return submitSignedDocumentSuccess(state, action.payload.response.data);
        case actionTypes.GET_LPP_PAYMENT_URL_SUCCESS:
            state = {...state, [field.PAYMENT_LPP]: action.payload.data.data};
            _setDashboardReducerCookie(state);
            return state;
        default:
            return state;
    }
};
export default dashboardReducer;
