import React from "react";
import {connect} from "react-redux";
import {Redirect, Switch, Route} from "react-router-dom";
import * as route from "../../shared/constant/route";

import Layout from "../../components/layout/Layout";
import * as routerSagaActions from "../../store/action/RouterSagaAction";
import GlobalErrorBoundary from "../../error/GlobalErrorBoundary";
import FeedbackPage from "../feedbackform/FeedbackFormPage";
import FeedbackPageSubmitted from "../feedbackform/FeedbackFormPageSubmitted";
import { ProtectedRoute } from "../../shared/utility/routeutility";
import * as utility from "../../shared/utility/utility";
import * as constant from "../../shared/constant/constant";
import * as _helpers from '../../shared/helpers'
import * as applicationActions from '../../store/action/ApplicationAction'
import * as field from '../../shared/constant/field'
import * as authActions from "../../store/action/AuthAction";
import * as MfaPageType from "../application/mfa/constant/MfaConstant";

const VerifyAccountPage = React.lazy(() => import("./verifyaccount/VerifyAccountPage"));
const VerifyAccountWithTokenPage = React.lazy(() => import("./verifyaccountwithtoken/VerifyAccountWithTokenPage"));
const OptOutWrapperPage = React.lazy(() => import("../application/optout/OptOutWrapperPage"));
const UpdatePasswordPage = React.lazy(() => import("./updatepassword/UpdatePasswordPage"));
const LoginPage = React.lazy(() => import("./login/LoginPage"));
const EmailLoginPage = React.lazy(() => import("../application/emaillogin/EmailLoginPage"));
const WebChatPage = React.lazy(() => import("../webchat/WebChatPage"));
const ResetPasswordPage = React.lazy(() => import("./resetpassword/ResetPasswordPage"));
const EmailOptOutPage = React.lazy(() => import("../emailoptout/EmailOptOutPage"));
const StateSelectionPage = React.lazy(() => import("../application/stateselection/StateSelectionPage"));
const DebtValidation = React.lazy(() => import("../application/debtvalidation/DebtValidationPage"));
const MfaVerificationPage = React.lazy(() => import("../application/mfa/MfaVerificationPage"));
const MfaSetUpPage = React.lazy(() => import("../application/mfa/MfaSetUpPage"));
const OtpLoginPage = React.lazy(() => import("../application/otplogin/OtpLoginPage"));

class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        if (
            this.props.location.pathname === route.STATE_LOGIN ||
            this.props.location.pathname === route.LOGIN
        ) {
            this.props.logoutUser();
        }
    }

    componentDidMount() {
        this.props.executeMiscAuthActions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // perform misc actions on update of route update
            this.props.executeMiscAuthActions();
        }
        if (prevProps.subIdTrackingThreshold !== this.props.subIdTrackingThreshold ) {

            let urlParams = _helpers.urlSplitting();
            urlParams.overrideTierKey = urlParams.tierkey ? true : false;

            urlParams.defaultTierkey = utility.getOrganizationTierKey(
                this.props.buildProfile,
                this.props.organizationTestTierKey,
                this.props.organizationProdTierKey
            );

            urlParams.subIdTrackingThreshold = this.props.subIdTrackingThreshold;
            this.props.saveUrlParams(urlParams);
        }
    }

    render() {
        return (
            <div className="application">
                <React.Suspense fallback={null}>
                    <GlobalErrorBoundary>
                        <Layout>
                            <Switch>
                                <Route path={route.EMAIL_LOGIN_PAGE} component={EmailLoginPage}/>
                                <Route path={route.OPTOUT} component={OptOutWrapperPage} />
                                <Route path="/verifyaccount/:userId" component={VerifyAccountPage} />
                                <Route
                                    path="/verifyemail/:userId/token/:token"
                                    component={VerifyAccountWithTokenPage}
                                />
                                <Route
                                    path="/updatepassword/:userId/uniqueResetId/:token"
                                    component={UpdatePasswordPage}
                                />
                                <Route path="/resetpassword" component={ResetPasswordPage} />
                                <ProtectedRoute isAuthorized={utility.getOrganizationId() !== constant.MONEYKEY_ORGANIZATION_ID ? true : false} path="/email-opt-out" redirect={route.STATE_LOGIN} component={EmailOptOutPage} />
                                <ProtectedRoute isAuthorized={utility.getOrganizationId() !== constant.MONEYKEY_ORGANIZATION_ID ? true : false} path={`/${route.DEBT_VALIDATION_RESPONSE_PAGE}`} redirect={route.STATE_LOGIN} component={DebtValidation} />
                                <ProtectedRoute isAuthorized={utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID ? true : false} path="/webchat" redirect={route.STATE_LOGIN} component={WebChatPage} />
                                <ProtectedRoute isAuthorized={utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID ? true : false} path="/feedback" redirect={route.STATE_LOGIN} component={FeedbackPage} />
                                <ProtectedRoute isAuthorized={utility.getOrganizationId() === constant.MONEYKEY_ORGANIZATION_ID ? true : false} path="/feedbacksubmitted" redirect={route.STATE_LOGIN} component={FeedbackPageSubmitted} />
                                <Route path="/statelogin" component={StateSelectionPage} />
                                <Route path="/statelogin/?state=:state?email=:email" component={StateSelectionPage} />
                                <Route path={route.PROFILE_MFA_VERIFICATION_PAGE} component={() => <MfaVerificationPage pageType={MfaPageType.MFA_PROFILE_SET_UP_PAGE} />} />
                                <Route path={route.OTP_EMAIL_LOGIN_PAGE} component={OtpLoginPage} />
                                <Route path={route.LOGIN_MFA_SET_UP_VERIFICATION_PAGE} component={() => <MfaVerificationPage pageType={MfaPageType.MFA_LOGIN_SET_UP_PAGE} />} />
                                <Route path={route.PROFILE_MFA_SET_UP_PAGE} component={() => <MfaSetUpPage pageType={MfaPageType.MFA_PROFILE_SET_UP_PAGE} />} />
                                <Route isAuthorized={true} path={route.LOGIN_MFA_SET_UP_PAGE} component={() => <MfaSetUpPage pageType={MfaPageType.MFA_LOGIN_SET_UP_PAGE} />} />
                                <Route isAuthorized={true} path={route.LOGIN_MFA_VERIFICATION_SMS_PAGE} component={() => <MfaVerificationPage pageType={MfaPageType.MFA_LOGIN_SMS_PAGE} />} />
                                <Route isAuthorized={true} path={route.LOGIN_MFA_VERIFICATION_EMAIL_PAGE} component={() => <MfaVerificationPage pageType={MfaPageType.MFA_LOGIN_EMAIL_PAGE} />} />
                                <Route path="/login" component={LoginPage} />
                                <Redirect to={"/statelogin"} />
                            </Switch>
                        </Layout>
                    </GlobalErrorBoundary>
                </React.Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    buildProfile: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.BUILD_PROFILE],
    organizationProdTierKey:
        state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_PROD_TIERKEY],
    organizationTestTierKey:
        state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_TEST_TIERKEY],
    subIdTrackingThreshold:
        state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUB_ID_TRACKING_THRESHOLD]
});

const mapDispatchToProps = dispatch => ({
    executeMiscAuthActions: () => dispatch(routerSagaActions.executeMiscAuthActions()),
    saveUrlParams: data => dispatch(applicationActions.saveUrlParams(data)),
    logoutUser: () => dispatch(authActions.logoutAction())
});

AuthPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthPage);

export default AuthPage;
